@import './../_variables'

.bannerContainer
    display: flex
    gap: 20px

.uploadImage
    position: relative

    &-logo
        width: 6vw
        height: 6vw
        background-color: $accent-color
        border-radius: 20px
        border: 2px solid $accent-color
        object-fit: contain
    
    &-imageH2
        width: 7.2vw
        height: 6vw
        background-color: $accent-color
        border-radius: 20px
        border: 2px solid $accent-color
        object-fit: contain
    
    &-imageH3
        width: 9vw
        height: 6vw
        background-color: $accent-color
        border-radius: 20px
        border: 2px solid $accent-color
        object-fit: contain
    
    &-imageH4
        width: 12vw
        height: 6vw
        background-color: $accent-color
        border-radius: 20px
        border: 2px solid $accent-color
        object-fit: contain
    
    &-upload
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        cursor: pointer
        opacity: 0

.captionImg
    display: flex
    align-items: center
    color: $darkGrey

.servicesGrid
    grid-gap: 10px
    align-self: stretch
    display: grid
    float: none
    gap: 10px
    grid-template-columns: repeat(4, 1fr)
    justify-content: flex-start
    margin: 20px 0
    padding-left: 0
    padding-right: 0
    position: relative
    align-items: flex-start