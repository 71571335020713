@import '../../../styles/_variables'

.menuItem
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    color: $darkGrey
    position: relative

    &-container
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        gap: 20px
        cursor: pointer
        flex: 1
        padding: 5px 10px
        border-radius: 10px

        &:hover
            background-color: rgba(0,0,0,0.03)
            text-decoration: none

        &:visited
            text-decoration: none

        &_span
            font-weight: bold
            font-size: 18px
            flex: 1
            text-decoration: none
            
        &_count
            font-weight: bold
            font-size: 16px
            color: rgba(82,86,90,0.5)
            
.menuItem.active
    color: $accent-color

.indicator
    width: 6px
    height: 35px
    background-color: $accent-color
    border-radius: 10px
    position: absolute
    left: $small-space*(-1.4)
