
.uploadField
    display: flex
    justify-content: space-between
    min-width: 300px
    padding: 5px 10px
    align-items: center
    border: 1px solid rgba(0,0,0,0.14)
    border-radius: 10px

    &-button
        position: relative

        &--upload
            opacity: 0
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            cursor: pointer        