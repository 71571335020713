.gridItem
    position: relative
    flex: 1
    background: #F5F5FB
    box-sizing: border-box
    background-repeat: no-repeat
    background-size: cover
    background-position: center center
    border-radius: 30px
    box-shadow: 0 5px 10px rgba(0,0,0,0.5)
    cursor: pointer
    overflow: hidden

    &::before
        content: ''
        display: block
        padding-top: 100%

    &-overlay
        position: absolute
        top: 0
        left: 0
        display: flex
        justify-content: flex-start
        align-items: flex-end
        width: 100%
        height: 100%
        padding: 10px 20px
    