.languagePopover
    padding: 20px
    display: flex
    flex-direction: column
    gap: 12px
    
.languageContainer
    display: flex
    gap: 10px
    position: relative
    cursor: pointer

    & img
        width: 32px
        height: 22px
        position: relative

    & span
        font-size: 16px
        color: #68696d