// Cold colors
$aqua: #7fdbff
$blue: #4785EF
$navy: #001f3f
$teal: #39cccc
$green: #4AD9AA
$olive: #3d9970
$lime: #89FF55

// Warm colors
$yellow: #ffdc00
$orange: #ff851b
$red: #ff4136
$fuchsia: #f012be
$purple: #642677
$maroon: #85144b
$deleteRed: #B50000

// Grayscale
$white: #ffffff
$silver: #dddddd
$gray: #aaaaaa
$black: #111111
$darkGrey: #68696D

// App Palette
$accent-color: var(--accent-color, $purple)
$app-background-color: #F5F5FB

$main-background-color: $white
$main-text-color: $darkGrey
$divider-color: rgba($black, .14)

$main-text-size: 14px
$main-border-radius: 8px

$small-space: 16px
$medium-space: 32px
$large-space: 64px

$phone-size: 600px
$tablet-size: 992px
$desktop-size: 1200px