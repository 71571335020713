@import '../../../styles/_variables'
    
.popoverOverflow .MuiPaper-root 
    overflow: visible !important
    border-radius: 20px

.temporaryAlert
    height: 24px
    background-color: $accent-color
    color: #fff
    width: 10vw
    padding: 10px
    height: 100%
    border-radius: 8px
    font-weight: 700

.notificationsList
    background-color: #fafafa
    width: 20vw
    height: 10vh
    padding: 10px
    overflow-y: scroll
    max-height: 200px
    border-radius: 15px

    &-noNotifications
        display: flex
        align-items: center
        justify-content: center
        opacity: 0.4
        height: 100%

    &-notification
        display: flex
        justify-content: space-between
        align-items: center
        gap: 15px
        color: #333

        &--iconsContainer
            display: flex
            justify-content: space-between
            gap: 8px
            align-items: center

            &_icon
                width: 30px
                height: 30px
                display: flex
                justify-content: center
                align-items: center
                border: 2px solid #000
                border-radius: 20px
                cursor: pointer