@import '../../styles/_variables'
@import '../../styles/_mixins'

.formatGroupBox
    display: flex
    flex-direction: column
    gap: 10px
    padding: 20px

.formatGroup-actions
    display: flex
    flex-direction: row
    justify-content: flex-end
    gap: 10px
    padding: 15px
    border-top: 1px solid rgba(0,0,0,0.07)
    margin-top: 20px



.formatGroupBox-content
    display: flex
    flex-direction: column
    gap: 10px
    padding: 20px

.radioBox-content
    display: flex
    flex-direction: column
    gap: 10px
    padding: 20px

.radioBox-actions
    display: flex
    flex-direction: row
    justify-content: flex-end
    gap: 10px
    padding: 15px
    border-top: 1px solid rgba(0,0,0,0.07)
    margin-top: 20px

.modalBox
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    padding: 20px
    background: #fff
    box-shadow: 0 3px 6px rgba(0,0,0,0.3)
    border-radius: 10px
    min-width: 30vw
    max-height: 95vh
    overflow-y: scroll
    &-title
        color: $darkGrey
        font-size: 20px
        font-weight: 600
        padding-bottom: 15px
        border-bottom: 1px solid rgba(0,0,0,0.07)
    &-buttonsRow
        display: flex
        flex-direction: row
        justify-content: flex-end
        gap: 10px
        padding: 15px
        border-top: 1px solid rgba(0,0,0,0.07)
        margin-top: 20px

.deleteBox
    &-text
        color: $deleteRed
        background: rgba($deleteRed,0.3)
        display: inline-block
        padding: 10px
        border-radius: 5px
        margin: 0 

.actionBox
    &-actions
        display: flex
        flex-direction: row
        justify-content: flex-start
        align-items: flex-start
        gap: 20px
        &--action
            padding: 10px
            border-radius: 5px
            margin: 10px 0
            cursor: pointer

.modalLoading
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    display: flex

.backdropWhite .MuiBackdrop-root
    background-color: rgba(255,255,255,0.8) !important


/// ON AIR META MODAL

.on-air-meta-modal-body
    width: 60vw
    +flexbox(flex-start, stretch, row, nowrap, $small-space)

.on-air-meta-modal-body-column:first-of-type
    flex: 2

.on-air-meta-modal-body-column:not(:first-of-type)
    flex: 1

.on-air-meta-modal-body-column
    +flexbox(flex-start, stretch, column, nowrap, 0)
    position: relative
    max-height: 75vh
    overflow-y: auto
    padding: 0 $small-space*.5 $small-space

    &__section:not(:last-of-type)
        padding-bottom: $small-space
        margin-bottom: $small-space
        border-bottom: 1px solid rgba(0, 0, 0, 0.14)

    &__section
        position: relative
        width: 100%
        +flexbox(flex-start, stretch, column, nowrap, $small-space*.5)

        &__subtitle
            line-height: 140%
            font-weight: 600
            font-size: 1em
            color: lighten($darkGrey, 5%)

.on-air-meta-modal-body-object-container
    position: relative
    height: 50%
    max-height: 50%
    overflow-y: auto
    margin-bottom: $small-space

.parsed-object-key
    color: $accent-color
    font-weight: bold

.tx-control-dashboard
    width: 60vw
    +flexbox(flex-start, stretch, row, nowrap, $small-space)
    background-color: #f7f8fa

    &__iframe
        position: relative
        height: 80vh
        width: 100%

.options-grid
    +flexbox(center, stretch, row, nowrap, $small-space)

    .red-button
        background-color: $deleteRed
        color: white

        &:hover
            background-color: darken($deleteRed, 5%)

    &__button
        +flexbox(center, center, row, nowrap, $small-space)
        padding: $small-space
        border-radius: 5px
        cursor: pointer
        background-color: #f7f8fa
        color: $darkGrey
        font-weight: 600
        font-size: 1em
        border: 1px solid rgba(0, 0, 0, 0.14)
        transition: background-color 0.2s

        &:hover
            background-color: #e9ecef