@import '../../../styles/_variables'

.map
  path:hover
    fill: #fff
    stroke: $accent-color

  path.selected
    fill: #fff!important
    stroke: $accent-color !important

  .regione
    transition: all 200ms ease-in-out
    cursor: pointer
