@import '../../styles/_variables.sass'

.backgroundPhone
    background-size: cover
    background-repeat: no-repeat
    height: 100%
    width: 381px
    padding: 30px
    padding-left: 12px

.sponsoredContainer::-webkit-scrollbar
    height: 10px

.sponsoredContainer::-webkit-scrollbar-track
    background-color: #f5f5fb
    border-radius: 10px

.sponsoredContainer::-webkit-scrollbar-thumb
    background-color: #cecece
    border-radius: 10px

.appPreviewContainer 
    padding: 20px
    background: rgb(100,38,119)
    background: linear-gradient(180deg, $accent-color 0%, rgba(0,0,0,1) 100%)
    margin: 20px
    width: 320px
    flex-shrink: 0

    &--sponsoredContainer
        display: flex
        gap: 10px
        margin-bottom: 10px
        overflow-x: scroll
        height: auto
        position: relative
        overflow-y: hidden
        flex-flow: row
        position: relative
        padding-bottom: 10px

        &--icon
            width: 60px
            height: 60px
            border-radius: 30px
            border: 2px solid $green
            display: flex
            justify-content: center
            align-items: center
            padding: 0 4px

            &_image
                max-width: 48px
                max-height: 48px
                border-radius: 30px
                object-fit: cover
                margin: 0
                padding: 0
        
    &--title
        display: flex
        justify-content: space-between
        gap: 5px
        align-items: flex-end
        margin-bottom: 20px

        &_text
            text-transform: uppercase
            font-size: 22px
            color: #3CF9B7
            margin: 0
            text-shadow: -1px 2px 1px #22b682, -2px 4px 1px #1e805f, -3px 6px 1px #154e3e, -4px 8px 1px #051812

        &_divider
            height: 2px
            background-color: $green
            flex: 1
            margin-bottom: 3px

    .dotCustom
        max-width: 42px
        margin: 10px auto
        display: flex
        flex-wrap: nowrap
        overflow: hidden
        gap: 5px
        align-items: center

        & > .carousel__dot
            width: 10px
            height: 10px
            border-radius: 20px
            background-color: #fff
            border: 0
            padding: 5px
            pointer-events: none
            transition: width 180ms ease-out, background-color 180ms ease-out

            &--selected
                background-color: $accent-color
                width: 15px
                height: 12px
                padding: 6px
    