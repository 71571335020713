@import '../../styles/_variables'
@import '../../styles/_mixins'

.c-button
    cursor: pointer
    position: relative
    width: 100%
    font-family: 'Poppins', sans-serif
    -moz-appearance: none
    -webkit-appearance: none
    border: none
    font-weight: 700
    border-radius: $main-border-radius
    font-size: 18px

    &:disabled
        opacity: .8
        cursor: not-allowed

.c-button__submit,
.c-button__submit-secondary
    padding-top: $small-space - $small-space/2
    padding-bottom: $small-space - $small-space/2

.c-button__submit
    color: $main-background-color
    background-color: $accent-color

.c-button__submit-secondary
    color: $main-background-color
    background-color: $gray