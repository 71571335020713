@import '../../styles/_variables'
@import '../../styles/_mixins'

.serviceTab
    box-shadow: 0 5px 10px rgba(0,0,0,0.3)
    padding: 20px
    border-radius: 20px

    &-title
        color: $darkGrey

    &-buttons
        display: flex
        justify-content: flex-end


// ON AIR META

.configuration-recap-container
    position: relative
    width: 100%
    +flexbox(flex-start, stretch, column, nowrap, $small-space*.5)
    margin: $small-space 0

    &__is-active-badge
        +flexbox(flex-start, center, row, nowrap, $small-space*.5)

    &__url-container
        position: relative
        width: 300px
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

.circular-status-badge
    +square($small-space)
    border-radius: 50%
    +flexbox(center, center, column, nowrap, 0)

    &::after
        content: ''
        z-index: 2
        +square($small-space*.64)
        border-radius: 50%

.active-badge
    background-color: rgba($green, .4)

    &::after
        background-color: $green

.not-active-badge
    background-color: rgba($gray, .4)

    &::after
        background-color: $gray