@import '../../styles/_variables'
@import '../../styles/_mixins'

.c-text-input
    +flexbox(center, flex-start, column, nowrap, 0px)
    position: relative
    flex:1

    .o-label,
    .o-input
        position: relative
        letter-spacing: .2px

    .o-label
        font-weight: 600
        margin: $small-space/3
        font-size: 14px
        text-transform: uppercase
        color: rgba($main-text-color, .45)

    .o-input-container__left-icon
        +flexbox(flex-start, center, row, nowrap,0px)

        .o-input-box__left-spaced
            padding-left: $medium-space + $small-space/2

        .o-input-icon
            margin-left: $small-space/4

    .o-input-container__right-icon
        +flexbox(flex-end, center, row, nowrap, 0px)

        .o-input-box__right-spaced
            padding-right: $medium-space + $small-space/2

        .o-input-icon
            margin-right: $small-space/4

    .o-input-container
        position: relative
        width: 100%

        .o-input-icon
            z-index: 1
            position: absolute

        .o-input:not(.o-input-box__left-spaced)
            padding-left: $small-space
        
        .o-input:not(.o-input-box__right-spaced)
            padding-right: $small-space

        .o-input
            width: 100%
            font-family: 'Poppins', sans-serif
            -moz-appearance: none
            -webkit-appearance: none
            padding-top: $small-space - $small-space/4
            padding-bottom: $small-space - $small-space/4
            border: none
            border-radius: $main-border-radius
            -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, .08)
            box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, .08)

            &:focus
                outline: 1px solid rgba($blue, .8)

            &:disabled
                opacity: .8
                cursor: not-allowed
