@import './_variables'
@import './_mixins'

*
  box-sizing: border-box
  outline: none

html,
body
  height: 100%
  overflow-x: hidden

body 
  position: relative
  margin: 0
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: $main-text-color
  background-color: $main-background-color
  font-size: $main-text-size
  font-weight: 400

#root
  height: 100%
  display: flex
  flex-direction: column

code 
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

input,
textarea,
button
  font-family: 'Poppins', sans-serif

p 
  margin: 0 !important

a
  text-decoration: none

// Utility classes ---

// Pages

.u-full-page-container
  position: relative
  width: 100%
  min-height: 100vh

.u-row-page
  +flexbox(flex-start, stretch, row, nowrap, 50px)

.u-column-page
  +flexbox(center, center, column, nowrap, 10px)

.u-elements-row
  +flexbox(flex-start, center, row, nowrap, 20px)

.u-page-sidebar-container
  position: relative
  margin-left: 290px
  padding: 30px
  background-color: #F5F5FB
  flex: 1

.titleRow
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center

.tabMenu
  display: flex
  gap: 22px
  padding-bottom: 10px
  margin-bottom: 20px
  overflow-x: auto
  height: auto
  position: relative
  overflow-y: hidden
  flex-flow: row
  position: relative
  text-transform: capitalize

  &::after
    height: 4px
    content: ''
    display: block
    background-color: rgba(100,38,119,0.14)
    position: absolute
    bottom: 0px
    width: 100%
    border-radius: 10px 

  &-tab
    margin-bottom: 0px
    font-size: 18px
    font-weight: 500
    color: rgba(82,86,90,0.8)
    padding: 0 5px
    cursor: pointer
    height: auto
    flex-shrink: 0
    flex-wrap: 0

    &_active
      margin-bottom: 0
      color: $accent-color
      font-size: 18px
      font-weight: 500
      padding: 0 5px
      cursor: pointer
      height: auto
      position: relative
      flex-shrink: 0
      flex-wrap: 0

      &::after
        content: ''
        display: block
        height: 4px
        border-radius: 5px
        background-color: $accent-color
        width: 80%
        left: 48%
        transform: translate(-50%,0)
        position: absolute
        bottom: -10px
        z-index: 99

// Spacing

.u-small-gap
  gap: $small-space

.u-medium-gap
  gap: $medium-space

.u-large-gap
  gap: $large-space

@supports not (gap: 1px)
  .u-small-gap
    *:not(:first-child)
      margin-left: $small-space
    *:not(:last-child)
      margin-right: $small-space

  .u-medium-gap
    *:not(:first-child)
      margin-left: $medium-space
    *:not(:last-child)
      margin-right: $medium-space

  .u-large-gap
    *:not(:first-child)
      margin-left: $large-space
    *:not(:last-child)
      margin-right: $large-space

// Text

.u-bold-text
  font-weight: 600

.u-large-text
  line-height: 1
  font-size: 4em

.u-medium-text
  font-size: 1.24em
  line-height: 1.4em

.u-normal-text
  font-size: 1em
  line-height: 1rem

.u-error-text
  margin: 0 !important
  color: $red
  padding: $small-space
  border-radius: $main-border-radius
  background-color: rgba($red, .14)

.u-success-text
  margin: 0 !important
  color: $green
  padding: $small-space
  border-radius: $main-border-radius
  background-color: rgba($green, .14)

.subtitle
  font-weight: 600
  font-size: 16px
  color: $darkGrey

.title
  font-weight: 600
  font-size: 22px
  color: $darkGrey

.offlineDot
  width: 20px
  height: 20px
  background-color: $accent-color
  border: 3px solid rgba($accent-color,0.5)
  border-radius: 10px
  position: relative
  &::before
    content: ''
    position: absolute
    width: 27px
    height: 27px
    border-radius: 25px
    background-color: rgba($accent-color,0.5)
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

.MuiTableCell-body
  border-bottom: 0 !important

input[type="checkbox"]
  -webkit-appearance: none
  background-color: #fff
  width: 25px
  height: 25px
  padding: 9px
  border-radius: 3px
  display: inline-block
  position: relative
  border: 2px solid #52565A

input[type="checkbox"]:checked
  background-color: $accent-color
  &::after
    content: ""
    position: absolute
    left: 7px
    top: 1px
    width: 8px
    height: 14px
    border: solid white
    border-width: 0 3px 3px 0
    -webkit-transform: rotate(45deg)
    -ms-transform: rotate(45deg)
    transform: rotate(45deg)

.fc-header-toolbar
  visibility: hidden
  height: 0
  margin: 0
  padding: 0

.fc-timegrid-event-harness-inset .fc-timegrid-event 
  border: 0 !important
  border-radius: 10px
  overflow: hidden

.fc-timegrid-event .fc-event-main
  padding: 0 !important

.fc .fc-timegrid-col.fc-day-today
  background-color: rgba(100, 38, 119, 0.14) !important

.fc-theme-standard th
  border-left: 0 !important
  border-right: 0 !important

.fc-timegrid-event-harness > .fc-timegrid-event
  margin: 3px !important

.fc-col-header-cell-cushion 
  color: $gray

.ant-select-dropdown
  z-index: 99999 !important

.ant-btn-dangerous.ant-btn-primary
  background: rgba($deleteRed, 0.14 ) !important
  border-color: $deleteRed !important
  border-radius: 5px !important
  font-weight: bold
  color: $deleteRed !important

.MuiToolbar-root p
  margin-bottom: 0 !important

.css-hyum1k-MuiToolbar-root 
    min-height: 0 !important

.hideFooter .css-9cgc89-MuiTableFooter-root
  display: none !important