@import '../../styles/_variables'
    
.reactPlayer div 
    width: 0 !important
    height: 0 !important

.audioPlayer
    background-color: $accent-color
    padding: 15px
    min-height: 10vh
    border-radius: 15px
    display: flex
    flex-direction: column

    &-meta
        color: #fff
        text-align: center
        position: relative
        width: 100%
        height: 25px
        overflow: hidden
    
    &-controls
        display: flex
        justify-content: center
        align-items: center
        flex-direction: row
        width: 100%

.videoPlayer
    position: fixed
    bottom: 7vh
    right: 5vw
    width: 25vw
    height: 20vh
    z-index: 99999998
    background: #000