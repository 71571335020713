

.header
    width: calc(100vw - 290px)
    height: 80px
    background-color: #fff
    border-bottom: 1px solid #e8e8e8
    padding: 20px
    margin-left: 290px
    display: flex
    justify-content: space-between
    align-items: center
    &-title
        color: #52565A
        font-size: 22px
    &-iconContainer
        display: flex
        justify-content: space-between
        align-items: center
        gap: 20px
        margin-right: 30px