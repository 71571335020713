@import '../../styles/_variables'
@import '../../styles/_mixins'

.o-link
  cursor: pointer
  &:focus
    outline: 1px solid $accent-color

.o-accent-link,
.o-hover-link
  display: inline-block

.o-accent-link,
.o-accent-link:active,
.o-accent-link:visited
  font-weight: 600
  color: $accent-color

.o-hover-link,
.o-hover-link:visited
  font-weight: 500
  color: lighten($main-text-color, 60%)
  transition: color 80ms linear

  &:hover
    color: $accent-color

.o-button-link
  +flexbox(center, center, row, nowrap,0px)
  background-color: rgba(0, 0, 0, .14)
  width: 100%
  -moz-appearance: none
  -webkit-appearance: none
  padding-top: $small-space - calc($small-space / 4)
  padding-bottom: $small-space - calc($small-space / 4)
  text-decoration: none !important
  font-weight: 500
  border: none
  border-radius: $main-border-radius

.o-text-colored-link
  color: inherit

.u-underline-none
  text-decoration: none

.u-underline-hover
  text-decoration: none
  &:hover
    text-decoration: underline

.u-underline-always
  text-decoration: underline
    