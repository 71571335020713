@font-face {
  font-family: 'fmworldfrontend';
  src: url('../font/fmworldfrontend.eot?36554271');
  src: url('../font/fmworldfrontend.eot?36554271#iefix') format('embedded-opentype'),
       url('../font/fmworldfrontend.woff2?36554271') format('woff2'),
       url('../font/fmworldfrontend.woff?36554271') format('woff'),
       url('../font/fmworldfrontend.ttf?36554271') format('truetype'),
       url('../font/fmworldfrontend.svg?36554271#fmworldfrontend') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fmworldfrontend';
    src: url('../font/fmworldfrontend.svg?36554271#fmworldfrontend') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fmworldfrontend";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-logout:before { content: '\e800'; } /* '' */
.icon-audio:before { content: '\e801'; } /* '' */
.icon-chevron-down:before { content: '\e802'; } /* '' */
.icon-chevron-left:before { content: '\e803'; } /* '' */
.icon-chevron-right:before { content: '\e804'; } /* '' */
.icon-chevron-top:before { content: '\e805'; } /* '' */
.icon-close:before { content: '\e806'; } /* '' */
.icon-double-chevron-down:before { content: '\e807'; } /* '' */
.icon-female:before { content: '\e808'; } /* '' */
.icon-collapse:before { content: '\e809'; } /* '' */
.icon-frequenze:before { content: '\e80a'; } /* '' */
.icon-home:before { content: '\e80b'; } /* '' */
.icon-expand:before { content: '\e80c'; } /* '' */
.icon-more:before { content: '\e80d'; } /* '' */
.icon-male:before { content: '\e80e'; } /* '' */
.icon-network:before { content: '\e80f'; } /* '' */
.icon-notifications:before { content: '\e810'; } /* '' */
.icon-pause:before { content: '\e811'; } /* '' */
.icon-play:before { content: '\e812'; } /* '' */
.icon-dab:before { content: '\e813'; } /* '' */
.icon-raccolte:before { content: '\e814'; } /* '' */
.icon-radio:before { content: '\e815'; } /* '' */
.icon-search:before { content: '\e816'; } /* '' */
.icon-sessions:before { content: '\e817'; } /* '' */
.icon-check:before { content: '\e818'; } /* '' */
.icon-user:before { content: '\e819'; } /* '' */
.icon-smartphone:before { content: '\e81a'; } /* '' */
.icon-video:before { content: '\e81b'; } /* '' */
.icon-questions:before { content: '\e81c'; } /* '' */
.icon-users:before { content: '\e81d'; } /* '' */
.icon-first-page:before { content: '\e81e'; } /* '' */
.icon-last-page:before { content: '\e81f'; } /* '' */
.icon-settings:before { content: '\e820'; } /* '' */
