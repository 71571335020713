@import '../../styles/_variables'
    
.collapsableSidebar 
    width: 250px
    height: calc(100vh - 80px)
    border-left: 2px solid #e8e8e8
    background-color: #fff
    position: fixed
    top: 80px
    right: 0
    display: flex
    flex-direction: column
    z-index: 999

    &-container
        display: flex
        justify-content: space-between
        align-items: flex-start
        flex-direction: column
        flex: 1

        &_controller
            padding: 30px 20px
            width: 100%
            display: flex
            flex-direction: row
            justify-content: center
            align-items: center

            &--control
                background: $accent-color
                width: 30px
                height: 30px
                display: flex
                justify-content: center
                align-items: center
                border-radius: 8px
                box-shadow: 0 3px 6px rgba(0,0,0,0.3)
                cursor: pointer

            &--line
                height: 2px
                flex: 1
                background-color: rgba(0,0,0,0.1)

            &--circle
                width: 10px
                height: 10px
                border-radius: 6px
                background-color: $accent-color

.floatButton
    background: $accent-color
    width: 30px
    height: 30px
    display: flex
    justify-content: center
    align-items: center
    border-radius: 8px
    box-shadow: 0 3px 6px rgba(0,0,0,0.3)
    cursor: pointer
    position: fixed
    bottom: 20px
    right: 0
    z-index: 99