@import '../../styles/_variables'
    
.sidebar 
    width: 290px
    height: 100vh
    border-right: 2px solid #e8e8e8
    background-color: #fff
    position: fixed
    top: 0
    left: 0
    display: flex
    flex-direction: column

    &-container
        display: flex
        justify-content: space-between
        flex-direction: column

        &_logo
            background-color: $accent-color
            margin: 20px
            padding: 10px
            border-radius: 10px
            width: auto
            display: flex

        &_menu
            padding: 20px
            margin: 30px 0
            width: 100%
            flex: 1
            overflow-y: auto
            max-height: calc(100vh - 300px) 

        &_profile
            background-color: rgba(0,0,0,0.03)
            padding: 20px
            width: 100%
            justify-content: space-between
            align-items: center
            display: flex
            gap: 20px
            border-top: 1px solid #e8e8e8
            border-bottom: 2px solid #e8e8e8
            align-self: flex-end

            &--notification
                position: relative
                cursor: pointer

                &-number
                    position: absolute
                    top: 3px
                    right: 0px
                    width: 20px
                    height: 20px
                    border-radius: 20px
                    background-color: $accent-color
                    color: #fff
                    display: flex
                    justify-content: center
                    align-items: center
                    font-weight: bold

            &--avatar
                background-color: $accent-color
                width: 40px
                height: 40px
                border-radius: 40px
                justify-content: center
                align-items: center
                display: flex
                cursor: pointer

                &__img
                    width: 40px
                    height: 40px
                    border-radius: 40px
                    object-fit: cover

            &--data
                flex: 1
                cursor: pointer
        
        &_copyright
            padding: 20px
            display: flex
            gap: 20px
            align-items: center

        &_bottom
            position: absolute
            bottom: 0
            z-index: 1000
            width: 100%
            background: #fff
