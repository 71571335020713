.bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 200px;
}

.bar_content {
    display: block; /* Important to give the content a width */
    max-width: 200px;
    width: 100%;
    transform: translateX(100%); /* Animation start out of the screen */

    /* Add the animation */
    animation: move 16s linear infinite /* infinite make reapeat the animation indefinitely */;
}

/* Create the animation */
@keyframes move {
    to {
        transform: translateX(-100%);
    }
}
