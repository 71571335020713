.pageController
    display: flex
    gap: 20px
    flex-direction: row
    justify-content: flex-end
    align-items: center
    margin: 20px 0
    flex: 1

    &-control
        cursor: pointer

    &-stats
        margin: 0