@import '../../styles/_variables'

.titleSetting
    font-size: 22px
    margin-top: 20px
    color: $darkGrey
    margin-bottom: 15px

.setting
    font-size: 18px
    color: $darkGrey
    font-weight: bold
    padding-bottom: 10px
    border-bottom: 1px solid #cecece
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 15px