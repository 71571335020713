.scheduleItem
    display: flex
    height: 100%
    width: 100%
    background-size: cover
    background-position: center
    cursor: pointer

    &-content
        display: flex
        flex-direction: column
        padding-top: 0.2vh
        padding-left: 10px
        padding-right: 10px
        justify-content: flex-end

        &_title
            font-weight: bold
            margin: 0
            font-size: 14px

        &_subtitle
            margin-top: 0
            margin-bottom: 5px