@import '../../styles/_variables'

.filterPopover
  width: 22vw
  min-width: 350px
  background: #fff
  padding: 20px 15px
  border-radius: 20px
  &-title
    font-size: 18px
    font-weight: bold
    color: $darkGrey
    border-bottom: 1px solid rgba(112,112,112,0.1)
    margin-bottom: 15px
  &-buttons
    display: flex
    gap: 10px
    margin-top: 20px

.popoverOverflow .MuiPaper-root 
    overflow: visible !important
    border-radius: 20px