@import './../_variables'
@import './../_mixins'

$text-color-accent-background: $main-background-color
$separator-line-background-color: $green

.logo
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    margin: $large-space 0

    img
        width: 100%
        height: auto

.u-full-page-container
    overflow: hidden

.c-login-interactive-panel,
.c-login-text-container
    position: relative
    min-height: 100vh

.c-login-interactive-panel
    width: 55%
    background-color: $app-background-color
    +flexbox(flex-start, flex-start, column, nowrap, 0px)

    &__main-panel,
    &::before
        border-bottom-right-radius: 60px
        -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, .08)
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, .08)

    &__main-panel
        position: relative
        min-height: 100%
        width: 100%
        z-index: 2
        left: (-1) * calc($small-space / 1.5)
        background-color: $app-background-color
        +flexbox(center, center, column, nowrap, 0px)
        overflow: hidden

    &::before,
    &::after
        content: ''
        +fullpageLayer()

    &::before
        bottom: -5px
        z-index: 1
        background-color: $separator-line-background-color
        -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, .08)
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, .08)

    &::after
        z-index: 0
        background-color: $accent-color

.o-login-inputs-container
    position: relative
    width: 50%
    max-width: 400px
    color: lighten($main-text-color, 30%)

    > *:not(:first-child)
        margin-top: $small-space

    > *:not(:last-child)
        margin-bottom: $small-space

.c-login-text-container
    width: 45%
    padding: $large-space
    background-color: $accent-color
    color: $text-color-accent-background
    display: flex
    flex-direction: column
    justify-content: space-between

.c-reset-password-panel
    position: relative
    width: 100%
    min-height: 100vh

    &__main-panel
        position: relative
        +square(100%)
        +flexbox(center, center, column, nowrap,0px)
        background-color: $app-background-color

.base-btn
    appearance: none
    -moz-appearance: none
    -webkit-appearance: none
    -ms-progress-appearance: none
    border: none
    cursor: pointer
    background: #e0e0ff
    color: #000
    line-height: 1
    position: relative
    overflow: hidden

    &:disabled
        opacity: .5
        cursor: not-allowed

.form-btn
    width: 100%
    border-radius: $small-space*.3
    padding: $small-space*.9 $small-space
    font-weight: 600
    +flexbox(center, center, row, nowrap, $small-space)
    transition: background-color .3s linear

    &__img
        width: auto
        height: 1.5rem
        margin-right: $small-space
    
.social-buttons-container
    +flexbox(center, center, row, wrap, $small-space)
    gap: $small-space
