@import '../_variables.sass'

.bundlesMenu::-webkit-scrollbar
    height: 10px

.bundlesMenu::-webkit-scrollbar-track
    background-color: #f5f5fb
    border-radius: 10px

.bundlesMenu::-webkit-scrollbar-thumb
    background-color: $accent-color
    border-radius: 10px

.bundlesMenu
    display: flex
    gap: 22px
    padding-bottom: 10px
    margin-bottom: 20px
    overflow-x: auto
    height: auto
    position: relative
    overflow-y: hidden
    flex-flow: row
    position: relative
    margin-top: 30px

    &_item
        margin-bottom: 0px
        font-size: 18px
        font-weight: 500
        color: rgba(82,86,90,0.8)
        padding: 0 5px
        cursor: pointer
        height: auto
        flex-shrink: 0
        flex-wrap: 0
        width: 15vw
        height: 17vh
        background-color: #f5f5fb
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        gap: 10px
        border-radius: 1vw
        position: relative
        min-height: 120px

        &-selected
            border: 3px solid $accent-color
            margin-bottom: 0px
            font-size: 18px
            font-weight: 500
            color: rgba(82,86,90,0.8)
            padding: 0 5px
            cursor: pointer
            height: auto
            flex-shrink: 0
            flex-wrap: 0
            width: 15vw
            height: 17vh
            background-color: #f5f5fb
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            gap: 10px
            border-radius: 1vw
            position: relative
            min-height: 120px

        &__logo
            width: 3.5vw
            height: 3.5vw
            border-radius: 3.5vw
            padding: 5px
            background-color: #fff
        
        &__icon
            position: absolute
            top: 5px
            right: 5px
            cursor: pointer
            padding: 0 0 15px 15px