.grid
    flex: 1
    display: grid
    justify-content: flex-start
    align-self: stretch
    float: none
    grid-gap: 20px
    gap: 20px
    grid-template-columns: repeat(7,1fr)
    position: relative
    flex-wrap: wrap
    margin: 30px 0

    &-controller
        display: flex
        justify-content: flex-end
        align-items: center


.loadingGrid
    height: 50vh
    display: flex
    justify-content: center
    align-items: center