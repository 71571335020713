@import '../../styles/_variables'
  
.tooltip 
  position: relative
  display: flex

.tooltip .tooltiptext 
  visibility: hidden
  width: 120px
  background-color: $accent-color
  color: #fff
  text-align: center
  border-radius: 6px
  padding: 5px 0
  position: absolute
  z-index: 9999
  bottom: -125%
  left: 50%
  margin-left: -60px
  opacity: 0
  transition: opacity 0.3s


.tooltip .tooltiptext::after 
  content: ""
  position: absolute
  bottom: 100%
  left: 50%
  margin-left: -5px
  border-width: 5px
  border-style: solid
  border-color: transparent transparent $accent-color transparent


.tooltip:hover .tooltiptext 
  visibility: visible
  opacity: 1
